<template>
  <div class="component">
    <b-form-group
      label="Business Name"
      label-for="business-name"
    >
      <validation-provider
        #default="{ errors }"
        name="Business Name"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="business-name"
            v-model="data.business_name"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="business-name"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Major Business Type"
      label-for="Major Business Type"
    >
      <validation-provider
        #default="{ errors }"
        name="Major Business Type"
      >
        <v-select
          v-model="data.major_business_type"
          :options="options.major_business_type"
          name="Major Business Type"
          :disabled="isreadonly"
          :clearable="true"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Minor Business Type"
      label-for="Minor Business Type"
    >
      <validation-provider
        #default="{ errors }"
        name="Minor Business Type"
      >
        <v-select
          v-model="data.minor_business_type"
          :options="options.minor_business_type"
          name="Minor Business Type"
          :disabled="isreadonly"
          :clearable="true"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group
      label="Transaction Type"
      label-for="Transaction Type"
    >
      <validation-provider
        #default="{ errors }"
        name="Transaction Type"
        rules="required"
      >
        <v-select
          v-model="data.transaction_type"
          :options="options.transaction_type"
          name="Transaction Type"
          :disabled="isreadonly"
          :searchable="false"
          @input="updateData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Land Size"
      label-for="living-area"
    >
      <validation-provider
        #default="{ errors }"
        name="Land Size"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <b-form-input
            id="land_size"
            v-model="data.land_size"
            :state="errors.length > 0 ? false:null"
            class="form-control-merge"
            name="land-size"
            :disabled="isreadonly"
            @input="updateData"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  props: {
    data: Object,
    options: Object,
    isreadonly: Boolean,
  },
  methods: {
    updateData() {
      this.$emit('update-data', this.data)
    },
  },
}
</script>

<style>

</style>
